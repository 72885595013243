<template>
  <div>
    <q-form ref="editForm">
      <!-- 설비 기본정보 -->
      <c-card title="LBL0003286" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- 저장 -->
            <c-btn
              v-if="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="equip"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <!-- 설비관리번호 -->
            <c-text
              :required="false"
              :editable="editable"
              label="ITEM번호"
              name="equipmentNo"
              v-model="equip.equipmentNo">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <c-process
              :required="true"
              :editable="editable"
              label="공정"
              name="processCd"
              v-model="equip.processCd">
            </c-process>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <!-- 설비유형 -->
            <c-equip-class
              :required="true"
              :editable="editable"
              label="LBL0001720"
              name="equipmentTypeCd"
              v-model="equip.equipmentTypeCd">
            </c-equip-class>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <!-- 설비명 -->
            <c-text
              :required="true"
              :editable="editable"
              label="LBL0001725"
              name="equipmentName"
              v-model="equip.equipmentName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-plant
              :required="true"
              :disabled="isOld"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="equip.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <!-- 폐기여부 -->
            <c-checkbox
              :editable="editable"
              :isFlag="true"
              label="LBL0001723"
              name="discardFlag"
              v-model="equip.discardFlag"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <!-- 폐기일 -->
            <c-datepicker
              :editable="editable && equip.discardFlag=='Y'"
              label="LBL0003299"
              name="discardDate"
              v-model="equip.discardDate"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <!-- 설비위치 -->
            <c-text
              :editable="editable"
              label="LBL0003282"
              name="equipmentLocation"
              v-model="equip.equipmentLocation">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <!-- 설치일 -->
            <c-datepicker
              :editable="editable"
              label="LBL0003293"
              name="installDate"
              v-model="equip.installDate"
            />
          </div>
        </template>
      </c-card>
      <c-card :isTitle="false" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <!-- 설비 도면 -->
            <c-upload
              :attachInfo="attachInfo"
              :editable="editable"
              label="LBL0003332">
            </c-upload>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        equipmentCd: '',
      }),
    },
    equip: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        processCd: '',  // 공정
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        discardFlag: 'N',
        discardDate: '',  // 폐기일자
        amount: '',  // 수량
        specs: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'EQUIPMENT_PID',
        taskKey: '',
      })
    },
  },
  data() {
    return {
      discardFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      editable: true,
      isSave: false,
      checkUrl: '',
      saveUrl: '',
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'equip.discardFlag': function() {
      if (this.equip.discardFlag == 'N') {
        this.equip.discardDate = '';
      }
    }
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.checkUrl = selectConfig.mdm.equipment.check.url;
      this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
      // code setting
      // list setting
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.mdm.equipment.info.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result1 => {
        if (_result1) {
          if (this.equip.equipmentNo) {
            this.$http.url = this.$format(this.checkUrl, this.equip.equipmentNo);
            this.$http.type = 'GET';
            this.$http.request((_result) => {
              if (this.isOld || _result.data === 0) {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',
                  message: 'MSGSAVE', // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.equip.regUserId = this.$store.getters.user.userId;
                    this.equip.chgUserId = this.$store.getters.user.userId;

                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                });
              } else {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message:
                  '동일한 ITEM번호가 존재합니다.', // 동일한 시퀀스 아이디가 존재합니다.
                  type: 'warning', // success / info / warning / error
                });
                return;
              }
            },);
          } else {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.equip.regUserId = this.$store.getters.user.userId;
                this.equip.chgUserId = this.$store.getters.user.userId;

                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.equipmentCd = result.data.equipmentCd;
      this.attachInfo.taskKey = result.data.equipmentCd;
      this.attachInfo.isSubmit = uid();
      this.$emit('getDetail');
    },
  }
};
</script>