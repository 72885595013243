var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBL0003286" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.equip,
                              mappingType: _vm.mappingType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveInfo,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: false,
                        editable: _vm.editable,
                        label: "ITEM번호",
                        name: "equipmentNo",
                      },
                      model: {
                        value: _vm.equip.equipmentNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.equip, "equipmentNo", $$v)
                        },
                        expression: "equip.equipmentNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
                  [
                    _c("c-process", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        label: "공정",
                        name: "processCd",
                      },
                      model: {
                        value: _vm.equip.processCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.equip, "processCd", $$v)
                        },
                        expression: "equip.processCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
                  [
                    _c("c-equip-class", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        label: "LBL0001720",
                        name: "equipmentTypeCd",
                      },
                      model: {
                        value: _vm.equip.equipmentTypeCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.equip, "equipmentTypeCd", $$v)
                        },
                        expression: "equip.equipmentTypeCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        label: "LBL0001725",
                        name: "equipmentName",
                      },
                      model: {
                        value: _vm.equip.equipmentName,
                        callback: function ($$v) {
                          _vm.$set(_vm.equip, "equipmentName", $$v)
                        },
                        expression: "equip.equipmentName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: true,
                        disabled: _vm.isOld,
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.equip.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.equip, "plantCd", $$v)
                        },
                        expression: "equip.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable,
                        isFlag: true,
                        label: "LBL0001723",
                        name: "discardFlag",
                      },
                      model: {
                        value: _vm.equip.discardFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.equip, "discardFlag", $$v)
                        },
                        expression: "equip.discardFlag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        editable: _vm.editable && _vm.equip.discardFlag == "Y",
                        label: "LBL0003299",
                        name: "discardDate",
                      },
                      model: {
                        value: _vm.equip.discardDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.equip, "discardDate", $$v)
                        },
                        expression: "equip.discardDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "LBL0003282",
                        name: "equipmentLocation",
                      },
                      model: {
                        value: _vm.equip.equipmentLocation,
                        callback: function ($$v) {
                          _vm.$set(_vm.equip, "equipmentLocation", $$v)
                        },
                        expression: "equip.equipmentLocation",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        editable: _vm.editable,
                        label: "LBL0003293",
                        name: "installDate",
                      },
                      model: {
                        value: _vm.equip.installDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.equip, "installDate", $$v)
                        },
                        expression: "equip.installDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-card",
            { staticClass: "cardClassDetailForm", attrs: { isTitle: false } },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo,
                        editable: _vm.editable,
                        label: "LBL0003332",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }